<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
const defaultLayout = 'NavLayout';
export default {
  name: 'AppLayout',
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout;
      return () => import(`@/components/Layout/${layout}.vue`);
    },
  },
};
</script>
