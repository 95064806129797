<template>
  <fullscreen-modal ref="innerTermModal">
    <template v-slot:title>
      Terms of Service
    </template>
    <template v-slot:content>
      Waiting for content
    </template>
  </fullscreen-modal>
</template>

<script>
import FullscreenModal from '@/components/Modal/FullscreenModal.vue';
export default {
  components: {
    FullscreenModal,
  },
};
</script>

<style></style>
