<template>
  <div class="compact-modal" v-show="showing">
    <div class="modal-content">
      <div class="close-btn" @click="hide" v-if="hasCloseButton">
        <i class="fa fa-times"></i>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CompactModal',
  props: {
    hasCloseButton: Boolean,
  },
  data() {
    return {
      showing: false,
    };
  },
  methods: {
    show() {
      this.showing = true;
    },
    hide() {
      this.showing = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.compact-modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: rgba(50, 58, 70, 0.5);
  z-index: 1000;
  @include flex-center-center;
  .modal-content {
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 1.5rem;
    flex: 0 0 0%;
    .close-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
}
</style>
