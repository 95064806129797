<template>
  <section class="login-form">
    <md-field>
      <md-input type="text" placeholder="Email" v-model="loginData.email" />
    </md-field>
    <md-field>
      <md-input
        type="password"
        placeholder="Password"
        v-model="loginData.pass"
      />
    </md-field>
    <div class="flex-between-center pb-3">
      <div>
        <input type="checkbox" v-model="rememberMe" />
        <span class="ml-1">จดจำฉันไว้</span>
      </div>
      <div class="link-primary">
        ลืมรหัสผ่าน
      </div>
    </div>
    <div class="py-3">
      <button class="btn btn-info btn-block" @click="$emit('login', loginData)">
        เข้าสู่ระบบ
      </button>
    </div>
    <div class="text-center mt-3">
      <span>ยังไม่มีบัญชีผู้ใช้</span>
      <span
        class="link-info font bold ml-1"
        @click="$emit('changeStep', 'signup')"
        >สมัครใช้งาน</span
      >
    </div>
  </section>
</template>

<script>
export default {
  name: 'LoginFormSection',
  data() {
    return {
      rememberMe: false,
      loginData: {
        email: '',
        pass: '',
      },
    };
  },
};
</script>
