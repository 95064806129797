import Vue from 'vue';
import numeral from 'numeral';

// Modules
import App from './App.vue';
import router from './router';
import store from './store';

// Global component
import spinner from './components/Spinner.vue';
import {
  MdField,
  MdMenu,
  MdList,
  MdButton,
} from 'vue-material/dist/components';

Vue.component('spinner', spinner);
// Vue.use(MdDatePicker);
Vue.use(MdField);
Vue.use(MdMenu);
Vue.use(MdList);
Vue.use(MdButton);

// Filter
Vue.filter('formatNumber', (value, format = '0,0', defaultVal = '') => {
  if (typeof value === 'string' || typeof value === 'number') {
    return numeral(value).format(format);
  }
  return defaultVal;
});

// Config
Vue.config.productionTip = false;

// CSS
import './assets/fonts/fonts.css';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
