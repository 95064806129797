<template>
  <div class="session-timeout-page">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Session timeout ...</h5>
        <p class="card-text">We're redirecting you to login again :)</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'SessionTimeoutPage',
  data() {
    return {};
  },
  mounted() {
    this.logoutFn();
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    async logoutFn() {
      await this.logout();
      setTimeout(
        () => this.$router.push({ name: 'Login' }).catch(() => {}),
        2000
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.session-timeout-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
