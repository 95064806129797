<template>
  <div class="nav-layout">
    <div class="navbar">
      <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        <router-link to="/logout">Logout</router-link>
      </div>
    </div>
    <div class="main-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.nav-layout {
}
</style>
