import Vue from 'vue';
import Cookies from 'js-cookie';
import api from '../../services/api';

export default {
  namespaced: true,
  state: {
    accessToken: '',
    tokenType: '',
    expiresIn: '',
    scope: '',
    currentUser: {},
  },
  getters: {
    accessToken: state => state.accessToken,
    currentUser: state => state.currentUser,
    currentId: state => {
      const c = state.currentUser;
      if (c && c.userId) {
        return c.userId;
      }
      return '';
    },
    currentUserFullName: state => {
      const c = state.currentUser;
      if (c && c.fullName) {
        return c.fullName;
      }
      return 'User';
    },
    isAuthenticated: state => {
      if (state.accessToken && state.expiresIn) {
        const now = new Date().getTime();
        const exp = new Date(state.expiresIn).getTime();
        return exp > now;
      }
      return false;
    },
  },
  actions: {
    async setAuthState({ commit }, authResult) {
      // Update cookie and auth header
      const expires = new Date(
        new Date().getTime() + authResult.expires_in * 1000
      );
      const optionsObject = {
        expires,
      };
      const hostString = window.location.host;
      let domain;
      if (hostString.includes('1palette.com')) {
        domain = '1palette.com';
      }
      if (domain) {
        optionsObject.domain = domain;
      }
      const newAuthObj = {
        accessToken: authResult.access_token,
        tokenType: authResult.token_type,
        scope: authResult.scope,
        expiresIn: expires,
      };
      Cookies.set('auth', newAuthObj, optionsObject);
      const token = `${authResult.token_type} ${authResult.access_token}`;
      api.setApiAuthHeader(token);
      // dispatch('getCurrentUser');
      commit('setAuthState', newAuthObj);
      return newAuthObj;
    },
    loadAuthState({ commit, getters, dispatch }) {
      const authResult = Cookies.getJSON('auth');
      if (authResult) {
        commit('setAuthState', authResult);
        const token = `${authResult.tokenType} ${authResult.accessToken}`;
        api.setApiAuthHeader(token);
        if (!getters.currentUser) {
          dispatch('getCurrentUser');
        }
      } else {
        commit('resetAuthState');
      }
      return authResult;
    },
    async getCurrentUser({ commit, getters }, forceLoad = false) {
      if (!forceLoad && getters.currentUser && getters.currentUser.id) {
        return getters.currentUser;
      }
      try {
        const result = await api.getTokenInfo();
        if (result) {
          commit('setCurrentUser', result);
        }
        return result;
      } catch (e) {
        // TODO handle here
      }
      return {};
    },
    updateUser({ commit }, data) {
      commit('setCurrentUser', data);
    },
    resetAuthState({ commit }) {
      commit('resetAuthState');
    },
    logout({ commit }) {
      const hostString = window.location.host;
      if (hostString.includes('1palette.com')) {
        Cookies.remove('auth', { path: '/', domain: '.1palette.com' });
      } else {
        Cookies.remove('auth');
      }
      commit('resetAuthState');
      if (window.localStorage) {
        window.localStorage.clear();
      }
    },
  },
  mutations: {
    setAuthState(state, data) {
      Vue.set(state, 'accessToken', data.accessToken);
      Vue.set(state, 'tokenType', data.tokenType);
      Vue.set(state, 'expiresIn', data.expiresIn);
      Vue.set(state, 'scope', data.scope);
    },
    resetAuthState(state) {
      Vue.set(state, 'accessToken', '');
      Vue.set(state, 'tokenType', '');
      Vue.set(state, 'expiresIn', '');
      Vue.set(state, 'scope', '');
    },
    setCurrentUser(state, data) {
      Vue.set(state, 'currentUser', data);
    },
  },
};
