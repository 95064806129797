function getQuery(inputStr, startStr) {
  const str = inputStr.replace(startStr, '');
  const seg = str.split('&');
  const resultObj = {};
  seg.forEach(s => {
    const subSplit = s.split('=');
    if (subSplit[0] && subSplit[1]) {
      const { 0: key, 1: value } = subSplit;
      resultObj[key] = value;
    }
  });
  return resultObj;
}

export default {
  getHashQuery: () => getQuery(window.location.hash, '#'),
  getSearchQuery: () => getQuery(window.location.search, '?'),
};
