<template>
  <div
    class="fullscreen-modal"
    :class="{ bordered: hasBorder }"
    v-show="showing"
  >
    <div class="close-btn" @click="hide">
      <i class="fa fa-times"></i>
    </div>
    <div class="content">
      <div class="font size32 bold mb-3">
        <slot name="title"></slot>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FullscreenModal',
  props: {
    hasBorder: Boolean,
  },
  data() {
    return {
      showing: false,
    };
  },
  methods: {
    show() {
      this.showing = true;
    },
    hide() {
      this.showing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  .content {
    background-color: #fff;
    height: 100%;
    width: 100%;
    padding: 50px;
  }
  .close-btn {
    position: fixed;
    top: 1rem;
    right: 1rem;
  }
  &.bordered {
    padding: 1rem;
    background-color: rgba(0,0,0,0.4);
    .close-btn {
      top: 2rem;
    right: 2rem;
    }
  }
}
</style>
