/* eslint-disable */
export default {
  segment: {
    info: {
      default: {
        color: '#ddd',
        title: 'Unknown Segment',
        subtitle: '',
        description: '',
      },
      Engage: {
        color: '#1C3361',
        title: 'Engaged customers',
        subtitle: 'คนที่สนใจสินค้า/แบรนด์',
        description:
          'กลุ่มลูกค้าที่มีความสนใจในสินค้า หรือแบรนด์ กด Like, share บนโพสต์ของเพจ แต่ไม่เคย comment หรือทักแชท',
      },
      Interact: {
        color: '#E8A93A',
        title: 'Customers considered',
        subtitle: 'คนที่กำลังพิจารณาสินค้า',
        description:
          'กลุ่มลูกค้าที่กำลังพิจารณาสินค้า comment หรือทักแชท แต่ไม่ระบุถึง intention ในการซื้อ และไม่เคยซื้อมาก่อน ต้องการข้อมูลเพิ่มเติม',
      },
      Buyer: {
        color: '#EE444F',
        title: 'Buyers',
        subtitle: 'คนที่ซื้อสินค้าแล้ว',
        description: 'กลุ่มลูกค้าที่มีการซื้อสินค้าแล้ว อย่างน้อย 1 ครั้ง',
      },
      "Qualify Lead": {
        color: '#76459B',
        title: 'Potential customers',
        subtitle: 'คนที่ตั้งใจจะซื้อสินค้า',
        description:
          'กลุ่มลูกค้าที่มีความตั้งใจจะซื้อสินค้า แต่ยัง ไม่ได้ซื้อ มี intention ของการจะซื้อในแชท แต่ยังไม่เคยซื้อสินค้า',
      },
      // Beyond here is not confirmed for name
      repeat_buyer: {
        color: '#EB6F3F',
        title: 'Repeat Buyers',
        subtitle: 'คนที่มีการซื้อซ้ำ',
        description:
          'กลุ่มลูกค้าที่เคยซื้อสินค้าไปแล้ว และมีการซื้อซ้ำ (สั่งซื้อมากกว่า 1 orders)',
      },
      lookalike_follower: {
        color: '#4692F5',
        title: 'Lookalike Followers',
        subtitle: 'คนที่คล้ายกับคนที่สนใจสินค้า',
        description:
          'กลุ่มลูกค้ากลุ่มใหม่ ที่มีพฤติกรรมใกล้เคียงกับ คนที่มีความสนใจในสินค้า หรือแบรนด์ (ใช้ได้เฉพาะ Facebook Ads)',
      },
      lookalike_prospect: {
        color: '#166BDA',
        title: 'Lookalike Prospects',
        subtitle: 'คนที่คล้ายกับคนที่กำลังพิจารณาสินค้า',
        description:
          'กลุ่มลูกค้ากลุ่มใหม่ ที่มีพฤติกรรมใกล้เคียงกับ คนที่กำลังพิจารณาสสินค้า หรือแบรนด์ (ใช้ได้เฉพาะ Facebook Ads)',
      },
      lookalike_buyer: {
        color: '#0C3C79',
        title: 'Lookalike Buyers',
        subtitle: 'คนที่คล้ายกับคนที่เคยซื้อ',
        description:
          'กลุ่มลูกค้ากลุ่มใหม่ ที่มีพฤติกรรมใกล้เคียงกับ คนที่ซื้อ และซื้อซ้ำ (ใช้ได้เฉพาะ Facebook Ads)',
      },
      champions: {
        color: '#0084FF',
        title: 'Champions',
        subtitle: 'ลูกค้าประจำชั้นดี',
        subtitle:
          'กลุ่มลูกค้าประจำชั้นดี ซื้อบ่อย ซื้อประจำ มูลค่าการสั่งซื้อสูง มักตื่นเต้นกับสินค้าใหม่ ๆ',
      },
      loyal_customers: {
        color: '#FF70B4',
        title: 'Loyal customers',
        subtitle: 'ลูกค้าประจำ',
        subtitle:
          '"กลุ่มลูกค้าชั้นดี มีความพอใจกับสินค้ามูลค่าการสั่งซื้อสูง ค่อนข้างซื้อบ่อย อ่อนไหวกับโปรโมชัน"',
      },
      potential_loyalists: {
        color: '#FF774B',
        title: 'Potential Loyalists',
        subtitle: 'มีแนวโน้มจะเป็นลูกค้าประจำ',
        subtitle:
          'กลุ่มลูกค้าหน้าใหม่ ที่มีโอกาสจะกลายเป็นลูกค้าประจำ สนใจผลประโยชน์ระยะยาวกับแบรนด์ ',
      },
      new_customers: {
        color :'#56D66C',
        title: 'New Customers',
        subtitle: 'ลูกค้าใหม่',
        subtitle:
          'กลุ่มลูกค้าใหม่ พึ่งเคยซื้อสินค้าครั้งแรก มีความสนใจอยากรู้รายละเอียดของสินค้าและบริการที่มากขึ้น',
      },
      promising: {
        color: '#0084FF',
        title: 'Promising',
        subtitle: 'ลูกค้าใหม่',
        subtitle:
          '"กลุ่มลูกค้าใหม่ ที่มีมูลการสั่งซื้อไม่สูงมากควรกระตุ้นให้ลูกค้ากลุ่มนี้กลับมาซื้อซ้ำ ด้วยสินค้าที่เกี่ยวข้องกับการสั่งซื้อครั้งก่อน"',
      },
      need_attention: {
        color: '#FF70B4',
        title: 'Need attention',
        subtitle: 'ลูกค้าทั่วไป',
        subtitle:
          'กลุ่มลูกค้าทั่วไป ที่สั่งซื้อเป็นบางครั้งบางคราว มูลค่าการสั่งซื้อไม่สูงมาก โปรโมชันสามารถกระตุ้นการซื้อซ้ำลูกค้ากลุ่มนี้ได้',
      },
      about_to_sleep: {
        color: '#FF774B',
        title: 'About to Sleep',
        subtitle: 'ลูกค้าทั่วไปที่เริ่มหายไป',
        subtitle:
          'กลุ่มลูกค้าทั่วไป ที่เริ่มห่างหายจากการสั่งซื้อสินค้า ควรกระตุ้นด้วยแคมเปญโปรโมชันใหญ่',
      },
      at_risk: {
        color :'#56D66C',
        title: 'At Risk',
        subtitle: '???',
        subtitle:
          'กลุ่มลูกค้าชั้นดี ที่มีแนวโน้มจะไม่กลับ มาซื้ออีก ควรเข้าไปพูดคุยเพื่อสอบถาม ความพึงพอใจกับเกี่ยวกับสินค้า',
      },
      cant_lose_them: {
        color: '#0084FF',
        title: 'Can’t Lose Them',
        subtitle: 'ลูกค้าประจำที่เริ่มหายไป',
        subtitle:
          'กลุ่มลูกค้าประจำชั้นดี ที่เริ่มหายไป ควรให้สิทธิพิเศษหรือโปรโมชันที่เหนือกว่าคนอื่น ๆ',
      },
      hibernating: {
        color: '#FF70B4',
        title: 'Hibernating',
        subtitle: 'ลูกค้าที่ซื้อน้อย',
        subtitle:
          'กลุ่มลูกค้าซื้อน้อย มูลค่าการสั่งซื้อต่ำ หายไปนาน ควรกระตุ้นถึงการมีตัวตนของแบรนด์และสินค้าที่เขาเคยซื้อ ',
      },
      lost: {
        color: '#FF774B',
        title: 'Lost',
        subtitle: 'ลูกค้าขาจร',
        subtitle:
          'กลุ่มลูกค้าขาจร ซื้อครั้งเดียวแล้วไป มูลค่าการสั่งซื้อต่ำ ควรกระตุ้นด้วย awareness campaign',
      },
    },
  },
};
