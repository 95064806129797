<template>
  <div id="app">
    <app-layout>
      <router-view />
    </app-layout>
  </div>
</template>

<script>
import AppLayout from '@/components/Layout/AppLayout.vue';

export default {
  name: 'App',
  components: {
    AppLayout,
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'G-able', Tahoma, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4a4a4a;
}

// Override Material
.md-field {
  margin: 4px 0 10px;
}
</style>
