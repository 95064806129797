<template>
  <div class="segment-page">
    <div class="container">
      <div class="d-flex justify-content-between mb-4">
        <div class="font bold size24 color-primary">SEGMENT</div>
        <div>
          <select
            id="page-select"
            class="form-select"
            v-model="selectedSourceId"
          >
            <option v-if="!segmentData" value="">Select Page</option>
            <option
              v-for="source in segmentData"
              :key="source.sourceId"
              :value="source.sourceId"
            >
              {{ source.pageName }}
            </option>
          </select>
        </div>
      </div>
      <div class="segment-section">
        <div class="title">Basic segments</div>
        <div class="toggle" @click="showBasicSegment = !showBasicSegment">
          <i
            class="fa"
            :class="{
              'fa-angle-down': showBasicSegment,
              'fa-angle-up': !showBasicSegment,
            }"
          ></i>
        </div>
      </div>
      <div class="row m-0 justify-content-start" v-show="showBasicSegment">
        <segment-card
          v-for="(segmentName, sIndex) in segmentConfig"
          :key="sIndex"
          :segment-name="segmentName"
          source-name="facebook"
          :count="segmentValueMap[segmentName]"
          @click="showSegmentFlowModal"
        />
      </div>
    </div>
    <segment-flow ref="segmentModal"></segment-flow>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import api from '@/services/api';
import SegmentCard from '@/views/Segment/SegmentCard.vue';
import SegmentFlow from '@/views/Segment/SegmentFlow.vue';

const segmentConfig = [
  'Engage',
  'Interact',
  'Qualify Lead',
  'Buyer',
  'repeat_buyer',
  'lookalike_follower',
  'lookalike_prospect',
  'lookalike_buyer',
];

export default {
  name: 'SegmentPage',
  components: {
    SegmentCard,
    SegmentFlow,
  },
  data() {
    return {
      segmentConfig,
      loading: true,
      selectedSourceId: '',
      segmentData: [],
      showBasicSegment: true,
      showAdvanceSegment: false,
    };
  },
  mounted() {
    console.log(this.currentId);
    api.getPageSegmentByUser(this.currentId).then(data => {
      this.segmentData = data;
      if (data[0]) {
        this.selectedSourceId = data[0].sourceId;
      }
    });
  },
  methods: {
    showSegmentFlowModal() {
      this.$refs.segmentModal.$refs.innerModal.show();
    },
  },
  computed: {
    ...mapGetters({
      currentId: 'auth/currentId',
    }),
    selectedSource() {
      if (
        this.segmentData &&
        this.segmentData.length > 0 &&
        this.selectedSourceId
      ) {
        return this.segmentData.find(s => s.sourceId === this.selectedSourceId);
      }
      return {};
    },
    segmentValueMap() {
      if (this.selectedSource && this.selectedSource.segments) {
        const mapObj = {};
        this.selectedSource.segments.forEach(element => {
          mapObj[element.segment] = element.size;
        });
        return mapObj;
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.segment-page {
  background-color: #fcfcfc;
  .segment-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid #ddd;
    .title {
      font-weight: bold;
      font-size: 20px;
      background-color: #fcfcfc;
      padding-right: 1rem;
    }
    .toggle {
      background-color: #fcfcfc;
      padding-left: 1rem;
    }
  }
}
</style>
