import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

// import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Callback from '../views/Callback.vue';

Vue.use(VueRouter);

// TODO add code spliting webpack chunk
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { layout: 'BlankLayout' },
  },
  {
    path: '/cb',
    name: 'FBCallback',
    component: Callback,
    meta: { layout: 'BlankLayout' },
  },
  {
    path: '/session-timeout',
    name: 'SessionTimeout',
    component: () => import('../views/SessionTimeout.vue'),
    meta: { layout: 'BlankLayout' },
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import('../views/Subscribe.vue'),
    meta: { layout: 'BlankLayout' },
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Segment/SegmentPage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '*',
    redirect: '/login',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const checkForSub = (to, from, next) => {
  store.dispatch('auth/getCurrentUser', true).then(user => {
    // console.log('USER', user);
    if (!user || (user && !user.userId)) {
      window.profileId = null;
      store.dispatch('auth/logout').then(() => {
        next({ name: 'Login' });
      });
    } else if (
      user &&
      user.platformStatus &&
      user.platformStatus.facebookStatus !== 'ACTIVE'
    ) {
      next({ name: 'Login' });
    } else {
      next();
    }
  });
};

router.beforeEach((to, from, next) => {
  // Virtual logout route -> would be redirect to login anyway - no need to interrupt
  if (to.path === '/login' && to.redirectedFrom === '/logout') {
    store.dispatch('auth/logout').then(() => {
      next();
    });
  } else if (to.matched.some(rec => rec.meta.requiresAuth)) {
    if (store.getters['auth/isAuthenticated']) {
      checkForSub(to, from, next);
    } else {
      // TODO use vuex persist to load state instead
      store.dispatch('auth/loadAuthState').then(() => {
        if (store.getters['auth/isAuthenticated']) {
          checkForSub(to, from, next);
        } else if (to.name === 'Login') {
          next();
        } else {
          next({ name: 'Login' });
        }
      });
    }
  } else {
    next();
  }
});

export default router;
