<template>
  <div class="base-card" @click="onClick">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'BaseCard',
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
<style lang="scss" scoped>
.base-card {
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.08);
  margin-right: 1rem;
  margin-bottom: 2rem;
  min-height: 270px;
  max-height: 270px;
  border-radius: 5px;
}
</style>
