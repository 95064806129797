<template>
  <fullscreen-modal
    class="segment-flow-modal"
    ref="innerModal"
    :has-border="true"
  >
    <template v-slot:content>
      <div class="segment-flow">
        <div class="step-head">
          <div class="font bold size24 color-primary text-center">
            สร้าง AD TARGETING
          </div>
          <stepper :currentStepIndex="stepIndex"></stepper>
        </div>
        <div class="step-content">
          <div class="step-0" v-if="stepIndex === 0">
            <div
              class="font size18 text-center mt-4"
              style="line-height: 21px; letter-spacing: 1px;"
            >
              คุณสามารถสร้าง Ad Targeting หรือ
              กลุ่มเป้าหมายสำหรับการทำแคมเปญโฆษณาของคุณ <br />
              ในหลากหลาย Channel เช่น Facebook, Google และ Email
              ได้ในที่เดียวกัน
            </div>
            <div class="step-content-expand">
              <base-card
                class="source-card"
                v-for="channel in channelList"
                :key="channel.name"
                :class="{ selected: selectedChannel === channel.name }"
                @click="selectedChannel = channel.name"
              >
                <img
                  class="source-logo"
                  :src="channel.logo"
                  :alt="channel.name + ' logo'"
                />
                <div class="font size18 bold">
                  {{ channel.title }}
                </div>
              </base-card>
            </div>
            <div class="bottom-row">
              <div class="btn btn-light" @click="cancelFlow()">ยกเลิก</div>
              <div class="btn btn-primary" @click="nextStep()">
                ดำเนินการต่อ
              </div>
            </div>
          </div>
          <div class="step-1" v-if="stepIndex === 1">
            <div class="font size18 bold my-4">รายการบัญชีโฆษณาทั้งหมด</div>
            <div class="step-content-expand">
              <div class="table-responsive acc-table">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center text-primary">
                        ชื่อบัญชีโฆษณา
                      </th>
                      <th scope="col" class="text-center text-primary">
                        หมายเลข ID
                      </th>
                      <th scope="col" class="text-center text-primary">
                        เจ้าของบัญชีโฆษณา
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="acc in adAccountList" :key="acc.adAccountId">
                      <td><input type="checkbox" /> {{ acc.info.name }}</td>
                      <td class="text-center">{{ acc.adAccountId }}</td>
                      <td class="text-center">N/A</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="bottom-row">
              <div class="btn btn-light" @click="previousStep()">ย้อนกลับ</div>
              <div class="btn btn-primary" @click="nextStep()">
                ดำเนินการต่อ
              </div>
            </div>
          </div>
          <div class="step-2" v-if="stepIndex === 2">
            <div class="step-content-expand">
              <div class="content-left">
                <div class="font size18 bold mt-2">
                  <i class="fa fa-cog"></i>
                  ตั้งค่ากลุ่มเป้าหมาย
                </div>
                <section class="field-section text-field">
                  <div class="font size14 bold mt-3">
                    ชื่อกลุ่มเป้าหมาย
                  </div>
                  <md-field class="pt-0">
                    <md-input type="text" v-model="targetingName" />
                  </md-field>
                </section>
                <section class="field-section">
                  <div class="font size14 bold mt-3">
                    ช่วงเวลาของ Audience
                  </div>
                  <div class="d-flex align-items-center mt-1 filter-date-row">
                    <md-field>
                      <label for="timeSince">เริ่มต้นจาก</label>
                      <md-select v-model="filterTimeSince" name="timeSince">
                        <md-option value="6month">6 เดือนก่อนหน้่า</md-option>
                        <md-option value="3month">3 เดือนก่อนหน้า</md-option>
                        <md-option value="continue">อัพเดทต่อเนื่อง</md-option>
                      </md-select>
                    </md-field>
                    <span class="mx-3">
                      -
                    </span>
                    <md-field>
                      <label for="timeUntil">จนถึงช่วงเวลา</label>
                      <md-select v-model="filterTimeUntil" name="timeUntil">
                        <md-option value="6month">6 เดือนก่อนหน้่า</md-option>
                        <md-option value="3month">3 เดือนก่อนหน้า</md-option>
                        <md-option value="continue">อัพเดทต่อเนื่อง</md-option>
                      </md-select>
                    </md-field>
                  </div>
                </section>
                <div>
                  <button
                    class="btn btn-info check-result-btn"
                    @click="analyzeTarget"
                  >
                    ตรวจสอบ
                  </button>
                </div>
              </div>
              <div class="content-right">
                <base-card class="result-card">
                  <div class="result-card-content" v-if="analyzedResult">
                    <div class="title">สรุปข้อมูลกลุ่มเป้าหมาย</div>
                    <div class="font bold size14">
                      จำนวนกลุ่มเป้าหมายที่อาจเข้าถึงได้:
                    </div>
                    <div class="font size-16 block-margin">10,055 คน</div>
                    <div class="font bold size-14">
                      รายละเอียดกลุ่มเป้าหมาย
                    </div>
                    <div class="d-flex flex-between-center">
                      <strong>ช่วงเวลา:</strong>
                      <div>01/10/2020 - 31/03/2021</div>
                    </div>
                  </div>
                  <div v-else>
                    <div>ดูสรุปข้อมูลกลุ่มเป้าหมายที่นี่</div>
                  </div>
                </base-card>
              </div>
            </div>
            <div class="bottom-row">
              <div class="btn btn-light" @click="previousStep()">ย้อนกลับ</div>
              <div class="btn btn-primary" @click="createAdTargeting()">
                สร้าง Ad Targeting
              </div>
            </div>
          </div>
          <div class="step-3" v-if="stepIndex === 3">
            <div class="step-content-expand">
              <img
                class="result-img"
                src="@/assets/create-success.png"
                alt="success"
              />
              <div class="title">
                สร้างกลุ่มเป้าหมาย Engaged Customer เรียบร้อยแล้ว
              </div>
              <div class="subtitle">
                คุณสามารถนำกลุ่มเป้าหมายจาก 1palette
                <wbr /> ไปใช้ยิงโฆษณาผ่านหน้า Facebook Ads ได้ทันที
              </div>
              <button class="btn btn-primary">
                ดูสถานะ
              </button>
              <div class="text-link">
                ไปยังบัญชีโฆษณาของคุณ
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </fullscreen-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import FullscreenModal from '@/components/Modal/FullscreenModal.vue';
import Stepper from '@/components/Stepper.vue';
import BaseCard from '@/components/Base/BaseCard.vue';

import fbLogo from '@/assets/logos/fb_logo_circle.png';
import googleLogo from '@/assets/logos/google_logo.png';
import gmailLogo from '@/assets/logos/gmail_logo.png';

import api from '@/services/api';

export default {
  name: 'SegmentFlow',
  components: {
    FullscreenModal,
    Stepper,
    BaseCard,
  },
  data() {
    return {
      stepIndex: 0,
      selectedChannel: 'facebook',
      selectedAdAccount: [],
      channelList: [
        {
          name: 'facebook',
          logo: fbLogo,
          title: 'Facebook',
        },
        {
          name: 'google',
          logo: googleLogo,
          title: 'Google Ads',
          disabled: true,
        },
        {
          name: 'email',
          logo: gmailLogo,
          title: 'Email',
          disabled: true,
        },
      ],
      adAccountList: [],
      targetingName: '',
      filterTimeSince: '',
      filterTimeUntil: '',
      monthValue: '',
      analyzedResult: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.adAccountList = await api
        .getOwnAdAccountList(this.currentId, false, true, false)
        .catch(() => {});
    },
    nextStep() {
      this.stepIndex += 1;
      // TODO check for valid before go next
    },
    previousStep() {
      this.stepIndex -= 1;
    },
    cancelFlow() {
      // TODO do cancel
    },
    createAdTargeting() {
      // TODO Implement this + API
      this.stepIndex += 1;
    },
    analyzeTarget() {
      // TODO Implement this
      this.analyzedResult = true;
    },
    onChange(e) {
      console.log(e);
      if (e.target.value !== this.monthValue) {
        alert('Hey it change');
        // this.monthValue = e.target.value;
      }
    },
  },
  computed: {
    ...mapGetters({
      currentId: 'auth/currentId',
    }),
  },
};
</script>

<style lang="scss" scoped>
.segment-flow-modal {
  height: 100vh;
  width: 100vw;
}
.segment-flow {
  // position: absolute;
  width: 100%;
  height: 100%;
  margin: 1rem;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 1000;
  .step-head {
    flex: 0;
    margin-top: 1rem;
  }
  .step-content {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .bottom-row {
      flex: 0;
      @include flex-between-center;
    }
    .step-0,
    .step-1,
    .step-2,
    .step-3,
    .step-content-expand {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
    }
    .step-0 {
      .step-content-expand {
        flex-direction: row;
        @include flex-center-center;
      }
      .source-select {
        flex: 1 1 auto;
        @include flex-center-center;
      }
    }
    .step-1 {
      .acc-table {
        flex: 1 1 auto;
      }
    }
    .step-2 {
      .step-content-expand {
        flex-direction: row;
        justify-content: center;
      }
      .content-left,
      .content-right {
        flex: 1 1 35%;
        max-width: 35%;
      }
      .filter-date-row .md-field {
        width: 40%;
      }
      .result-card {
        background-color: #fcfcfc;
        margin-left: 80px;
        min-height: 200px;
        .title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 18px;
        }
        .block-margin {
          margin-bottom: 33px;
        }
      }
      .check-result-btn {
        background-color: #d1edf0;
        border: #d1edf0;
        color: #19a4b5;
        min-width: 125px;
        margin-top: 24px;
      }
      .field-section {
        margin-top: 32px;
        &.text-field .md-field {
          max-width: 260px;
        }
      }
    }
    .step-3 {
      .step-content-expand {
        @include flex-center-center;
        .result-img {
          width: 180px;
          height: 180px;
          margin-bottom: 30px;
        }
        .title {
          font-size: 18px;
          line-height: 28px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .subtitle {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 38px;
        }
        .btn {
          min-width: 125px;
          margin-bottom: 30px;
        }
        .text-link {
          color: #19a4b5;
          cursor: pointer;
        }
      }
    }
  }
  .source-card {
    @include flex-center-center;
    flex-direction: column;
    width: 200px;
    height: 200px;
    min-height: 200px !important;
    max-height: 200px !important;
    border: 1px solid transparent;
    &.selected {
      border: 1px solid #19a4b5;
    }
    .source-logo {
      margin-bottom: 30px;
      height: 60px;
      width: auto;
    }
  }
}
</style>
