<template>
  <base-card
    class="segment-card col-lg-4 col-md-4 col-sm-4 col-xs-12"
    @click="$emit('click')"
  >
    <div class="segment-head">
      <div class="color-indicator" :style="indicatorStyle"></div>
      <div class="title-block">
        <div class="title">
          {{ segmentInfo.title }}
        </div>
        <div class="subtitle">({{ segmentInfo.subtitle }})</div>
      </div>
      <div class="more-button">
        <i class="fa fa-ellipsis-v"></i>
      </div>
    </div>
    <div class="description">
      {{ segmentInfo.description }}
    </div>
    <div class="counter" v-if="count !== -1">
      <div>
        <span class="counter-value">{{ count | formatNumber('0,0', 0) }}</span>
        <span>คน</span>
      </div>
      <div class="counter-change">
        ...
      </div>
    </div>
    <div class="sources-list d-flex">
      <div class="source" v-if="sourceName === 'facebook'">
        <img
          class="source-img"
          src="@/assets/logos/fb_logo_circle.png"
          alt="FB Logo"
          width="35"
          height="35"
        />
      </div>
    </div>
  </base-card>
</template>

<script>
import BaseCard from '@/components/Base/BaseCard.vue';
import s from '@/strings.js';

export default {
  name: 'SegmentCard',
  components: { BaseCard },
  props: {
    segmentName: String,
    sourceName: String,
    count: {
      type: [Number, String],
      default: -1,
    },
  },
  computed: {
    segmentInfo() {
      if (typeof this.segmentName !== 'undefined') {
        return s.segment.info[this.segmentName];
      }
      return s.segment.info['default'];
    },
    indicatorStyle() {
      if (this.segmentInfo && this.segmentInfo.color) {
        return {
          backgroundColor: this.segmentInfo.color,
        };
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.segment-card {
  display: flex;
  flex-direction: column;
  flex: 0 0 0%;
  min-width: 270px;
  .segment-head {
    flex: none;
    display: flex;
    .color-indicator {
      width: 10px;
      height: 10px;
      min-width: 10px;
      max-height: 10px;
      border-radius: 20px;
      margin-right: 1rem;
      margin-top: 8px;
    }
    .title-block {
      flex: 1 1 auto;
      font-weight: bold;
      .title {
        font-size: 18px;
        color: #4a4a4a;
      }
      .subtitle {
        font-size: 14px;
        color: #8c8c8c;
      }
    }
    .more-button {
      flex: none;
      padding-top: 2px;
    }
  }
  .description {
    flex: 1 1 auto;
    margin: 0.5rem 0;
    font-size: 14px;
    color: #8c8c8c;
    margin-bottom: 0.5rem;
  }
  .counter {
    font-size: 14px;
    color: #4a4a4a;
    font-weight: bold;
    .counter-value {
      font-size: 24px;
      margin-right: 0.5rem;
    }
    .counter-change {
      font-weight: normal;
      font-size: 12px;
      color: #8c8c8c;
      .increase {
        color: #0fb053;
      }
      .decrease {
        color: #d9150f;
      }
    }
  }
  .sources-list {
    margin-top: 12px;
    .source-img {
      width: 35px;
      height: 35px;
      min-width: 35px;
      min-height: 35px;
      margin-right: 5px;
      -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
    }
  }
}
</style>
