<template>
  <spinner />
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/services/api';
import helper from '@/services/helper';

export default {
  name: 'Callback',
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      setAuthState: 'auth/setAuthState',
      getCurrentUser: 'auth/getCurrentUser',
    }),
    async init() {
      // Load hash query
      this.qsData = helper.getHashQuery();
      this.qsSearch = helper.getSearchQuery();
      let parseState;
      try {
        parseState = JSON.parse(decodeURIComponent(this.qsData.state));
        this.result = parseState;
      } catch (e) {
        console.error('PARSE FAILED', e);
      }
      if (this.qsData.error || this.qsSearch.error) {
        if (this.qsSearch.state) {
          if (parseState && parseState.action) {
            if (parseState.action === 'login') {
              this.goResult(parseState, false, 'Login');
              return;
            }
          }
        }
        this.goResult(parseState, false, 'Login');
      } else if (this.qsData.code || this.qsData.access_token) {
        if (parseState && parseState.action) {
          if (parseState.action === 'login') {
            let loginFrom = 'facebook';
            const authResult = await api.authenticate(
              this.qsData.code,
              loginFrom,
              false
            );
            await this.setAuthState(authResult);
            await this.getCurrentUser(true);
            this.goResult(parseState, true, 'Home');
          }
        }
      }
    },
    async goResult(parseState, success, defaultName) {
      if (parseState && parseState.action) {
        let target = '';
        const query = {};
        const { action } = parseState;
        if (action === 'login') {
          if (success) {
            // Done login
            if (this.debug) {
              // console.log('Do nothing');
              return false;
            }
            target = 'Home';
          } else {
            target = 'Login';
          }
        }
        if (target) {
          this.$router.push({ name: target, query }).catch(() => {});
          return false;
        }
      } else {
        console.error('No action found - go default', defaultName);
        this.$router.push({ name: defaultName }).catch(() => {});
      }
      return false;
    },
  },
};
</script>
