async function loadFacebookSDK(d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = 'https://connect.facebook.net/en_US/sdk.js';
  fjs.parentNode.insertBefore(js, fjs);
}

export default {
  init: appId => {
    // Unused - use script tag from fb instead
    window.fbAsyncInit = async () => {
      console.log('FB LOADED');
      window.FB.init({
        appId: appId,
        cookie: true,
        version: 'v10.0',
        status: true,
      });
    };
    return loadFacebookSDK(document, 'script', 'fb-sdk');
  },
  getStatus: () => {
    return new Promise((resolve, reject) => {
      if (window.FB) {
        window.FB.getLoginStatus(res => {
          resolve(res);
        });
      } else {
        reject();
      }
    });
  },
  login: opt => {
    return new Promise((resolve, reject) => {
      if (window.FB) {
        window.FB.login(res => {
          if (res && res.authResponse) {
            resolve(res.authResponse);
          }
        }, opt);
      } else {
        reject();
      }
    });
  },
  logout: () => {
    return new Promise((resolve, reject) => {
      if (window.FB) {
        window.FB.getLoginStatus(function(response) {
          if (response && response.status === 'connected') {
            window.FB.logout(function() {
              resolve();
            });
          } else {
            resolve();
          }
        });
      } else {
        reject();
      }
    });
  },
};
