<template>
  <div class="login">
    <div class="d-flex flex-column h-100 w-100">
      <div class="row w-100">
        <div class="col-md-4 bg-gray">
          &nbsp;
        </div>
        <div class="col-md-8 text-center bg-white login-content">
          <section class="fix-width-section">
            <img
              src="@/assets/1palette-nopad.svg"
              alt="logo"
              style="height: 40px;"
            />
            <login-fb-section
              v-if="step === 'signup' || step === 'login'"
              :step="step"
              @click="loginUsingFacebookSDK"
            />
          </section>
          <signup-form
            class="fix-width-section"
            v-if="step === 'signup' || step === 'fb-new'"
            :step="step"
            :token="accessToken"
            :prefill="prefillSignupData"
            @changeStep="onChangeStep"
          />
          <login-form
            class="fix-width-section"
            v-if="step === 'login'"
            @login="loginWithEmail"
            @changeStep="onChangeStep"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

// import Cookies from 'js-cookie';
import api from '@/services/api';
import fb from '@/services/fb';

import LoginFBSection from './Login/LoginFBSection.vue';
import SignupForm from './Login/SignupForm.vue';
import LoginForm from './Login/LoginFormSection.vue';

export default {
  name: 'Login',
  components: {
    'login-fb-section': LoginFBSection,
    SignupForm,
    LoginForm,
  },
  data() {
    return {
      accessToken: '',
      step: 'signup',
      prefillSignupData: null,
    };
  },
  methods: {
    ...mapActions({
      setAuthState: 'auth/setAuthState',
    }),
    async loginUsingFacebookSDK() {
      // Using getLoginStatus
      //  - User logged in to facebook & authorized app (resp.status === 'connected')
      //  - Not login facebook or not authorized [unknown or unauthorized]

      // TODO! refactor the catch

      // Force logout and login
      await fb.logout().catch(() => {});
      const authResponse = await fb
        .login({ scope: api.loginOnlyScope })
        .catch(() => {});
      if (authResponse && authResponse.accessToken) {
        const { accessToken } = authResponse;
        const checkResult = await api
          .checkAccountFacebook(accessToken)
          .catch(() => {});
        if (checkResult) {
          this.accessToken = accessToken;
          // console.log(checkResult);
          if (checkResult.isAccountExist) {
            // Already had account -> authen -> navigate to home
            // Authen
            const oauthResult = await api
              .loginWithFacebook(accessToken)
              .catch(e => {
                console.error('Cannot login with fb', e);
              });
            if (oauthResult && oauthResult.access_token) {
              this.setAuthState(oauthResult).then(() => {
                this.$router.push({ name: 'Home' });
              });
            } else {
              // TODO show error dialog
            }
          } else {
            // Setup form and show fb register flow
            const data = {};
            if (checkResult.name) {
              data.fullname = checkResult.name;
            } else if (checkResult.firstName && checkResult.lastname) {
              data.fullname = `${checkResult.firstName} ${checkResult.lastName}`;
            }
            data.email = checkResult.email;
            data.tel = checkResult.tel;
            this.prefillSignupData = data;
            this.step = 'fb-new';
          }
        }
      }
    },
    async loginWithEmail({ email, pass }) {
      // console.log('Do Login', loginData);
      const result = await api.loginWithEmail(email, pass).catch(e => {
        console.error('Login error', e);
      });
      // Login Result got access_token, expires_in
      if (result && result.access_token) {
        await this.setAuthState(result);
        this.$router.push({ name: 'Home' });
      } else {
        // TODO show error dialog
      }
    },
    onChangeStep(step) {
      this.step = step;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.login {
  .login-content {
    height: 100vh;
    @include flex-center-center;
    flex-direction: column;
  }
  .fix-width-section {
    width: 50%;
    min-width: 320px;
  }
}
</style>
