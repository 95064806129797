<template>
  <div class="subscribe">
    <div class="row h-100">
      <div
        class="col-md-10 offset-md-1 col-lg-8 offset-lg-2 d-flex flex-column"
      >
        <div class="step-head">
          <div class="logo mb-4">
            <img
              src="@/assets/1palette-nopad.svg"
              alt="logo"
              style="height: 36px;"
            />
          </div>
          <div class="title mb-4">สร้างแบรนด์ของคุณ</div>
          <stepper :stepperState="stepList" :currentStepIndex="stepIndex" />
        </div>
        <div class="step-content">
          <base-card class="row card-h100">
            <div
              class="step-0 col-sm-10 offset-sm-1 col-md-6 offset-md-3 flex-column flex-center-center"
              v-if="stepIndex === 0"
            >
              <div class="step-content-title">
                แบรนด์ของคุณชื่ออะไร?
              </div>
              <div class="w-100">
                <md-field>
                  <md-input
                    type="text"
                    placeholder="Brand Name"
                    v-model="brandName"
                  />
                </md-field>
                <md-field>
                  <md-select
                    v-model="brandCategory"
                    name="brand-category"
                    placeholder="Category"
                  >
                    <md-option value="catA">Category A</md-option>
                    <md-option value="catB">Category B</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div
              class="step-1 w-100 d-flex flex-column flex-center-center"
              v-if="stepIndex === 1"
            >
              <div class="step-content-title">
                เลือกแพ็คเกจ
              </div>
              <div class="step-content-expand flex-wrap">
                <base-card
                  class="package-card"
                  v-for="pkg in packageList"
                  :key="pkg.id"
                  :class="{ selected: selectedPackage === pkg.id }"
                  @click="selectedPackage = pkg.id"
                >
                  <div class="selected-circle">
                    <i class="fa fa-check"></i>
                  </div>
                  <div class="title">{{ pkg.name }}</div>
                  <div class="price-row">
                    <span class="price">{{ pkg.price | formatNumber }}฿</span>
                    <span class="unit">/เดือน</span>
                  </div>
                  <div class="adv" v-for="advRow in pkg.adv" :key="advRow">
                    <i class="fa fa-check"></i>{{ advRow }}
                  </div>
                </base-card>
              </div>
            </div>
            <div
              class="step-2 col flex-column flex-center-center"
              v-if="stepIndex === 2 && !showConnectResult"
            >
              <div class="step-content-title mb-2">
                บัญชีโซเชียล (Social Accounts) ของแบรนด์
              </div>
              <div class="step-content-subtitle">
                คุณสามารถเชื่อมต่อได้มากกว่า 1 บัญชีโซเชียล
              </div>
              <div class="step-content-expand flex-wrap">
                <div class="source-card" @click="connectSocialFB">
                  <img
                    src="@/assets/logos/fb_logo_circle.png"
                    alt="facebook connect"
                    class="source-logo"
                  />
                  <div class="source-title">
                    Facebook
                  </div>
                  <div class="btn btn-info btn-block connect-btn">
                    Connect
                  </div>
                </div>
                <div class="source-card">
                  <img
                    src="@/assets/logos/line_logo.png"
                    alt="line connect"
                    class="source-logo"
                  />
                  <div class="source-title">
                    Line
                  </div>
                  <div class="btn btn-info btn-block connect-btn">
                    Connect
                  </div>
                </div>
                <div class="source-card">
                  <img
                    src="@/assets/logos/instagram_logo.png"
                    alt="instagram connect"
                    class="source-logo"
                  />
                  <div class="source-title">
                    Instagram
                  </div>
                  <div class="btn btn-info btn-block connect-btn">
                    Connect
                  </div>
                </div>
              </div>
            </div>
            <div
              class="step-2 col pt-3"
              v-if="stepIndex === 2 && showConnectResult"
            >
              <div class="d-flex justify-content-between">
                <div>
                  <div class="step-content-title mb-2 text-left">
                    บัญชีโซเชียล (Social Accounts) ของแบรนด์
                  </div>
                </div>
                <button
                  class="btn btn-sm btn-info"
                  @click="showConnectResult = false"
                >
                  + เชื่อมต่อบัญชี
                </button>
              </div>
              <div class="step-content-subtitle text-left mb-4">
                คุณสามารถเชื่อมต่อได้มากกว่า 1 บัญชีโซเชียล
              </div>
              <div class="connect-social-acc pt-4">
                <section
                  v-if="
                    connectedSocialAccount.facebook &&
                      connectedSocialAccount.facebook.length > 0
                  "
                >
                  <div class="source-name-row">
                    <div class="wrapper">
                      <img
                        src="@/assets/logos/fb_logo_circle.png"
                        alt="facebook connect"
                        class="source-logo"
                      />
                      <span class="source-name">
                        FACEBOOK
                      </span>
                      <span class="source-count">
                        ({{ connectedSocialAccount.facebook.length }})
                      </span>
                    </div>
                  </div>
                  <div class="acc-wrapper">
                    <base-card
                      class="source-long-row"
                      v-for="(src, srcIndex) in connectedSocialAccount.facebook"
                      :key="srcIndex"
                    >
                      <!-- TODO insert real img -->
                      <div class="circle-img"></div>
                      <div class="middle-content">
                        <div class="title">{{ src.name }}</div>
                        <div class="subtitle">
                          เชื่อมต่อ {{ src.createdAt }} | โดย
                          <strong>{{ src.owner }}</strong>
                        </div>
                      </div>
                      <button
                        class="btn btn-light"
                        @click="onDeleteSocial('facebook', srcIndex)"
                      >
                        ลบออก
                      </button>
                    </base-card>
                  </div>
                </section>
                <!-- TODO add more source section to handle -->
              </div>
            </div>
            <div
              class="step-3 col-md-8 offset-md-2 d-flex flex-column"
              v-if="stepIndex === 3 && !showAccountResult"
            >
              <div class="d-flex flex-column flex-auto-grow flex-center-center">
                <div class="step-content-title mb-2">
                  เลือกบัญชีโฆษณา (Ad Accounts)
                </div>
                <div class="step-content-subtitle">
                  คุณสามารถเลือกบัญชีโฆณาที่ต้องการได้มากกว่า 1 บัญชี
                </div>
                <div class="step-content-expand">
                  <div class="source-card" @click="onConnectingAccount">
                    <img
                      src="@/assets/logos/fb_logo_circle.png"
                      alt="facebook connect"
                      class="source-logo"
                    />
                    <div class="source-title">
                      Facebook
                    </div>
                    <div class="btn btn-info btn-block connect-btn">
                      Connect
                    </div>
                  </div>
                  <div class="source-card">
                    <img
                      src="@/assets/logos/google_logo.png"
                      alt="line connect"
                      class="source-logo"
                    />
                    <div class="source-title">
                      Google Ads
                    </div>
                    <div class="btn btn-info btn-block connect-btn">
                      Connect
                    </div>
                  </div>
                  <div class="source-card">
                    <img
                      src="@/assets/logos/gmail_logo.png"
                      alt="gmail connect"
                      class="source-logo"
                    />
                    <div class="source-title">
                      Email
                    </div>
                    <div class="btn btn-info btn-block connect-btn">
                      Connect
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center text-color-subtitle">
                *หมายเหตุ: คุณสามารถข้ามขั้นตอนนี้เพื่อทำภายหลังได้ที่หน้า
                ตั้งค่าบัญชีโฆษณา
              </div>
            </div>
            <div
              class="step-3 col pt-3"
              v-if="stepIndex === 3 && showAccountResult"
            >
              <div class="d-flex justify-content-between">
                <div>
                  <div class="step-content-title mb-2 text-left">
                    บัญชีโฆษณา (Ad Accounts)
                  </div>
                </div>
                <button
                  class="btn btn-sm btn-info"
                  @click="showAccountResult = false"
                >
                  + เชื่อมต่อบัญชีโฆษณา
                </button>
              </div>
              <div class="step-content-subtitle text-left mb-4">
                คุณสามารถเลือกบัญชีโฆณาที่ต้องการได้มากกว่า 1 บัญชี
              </div>
              <div class="connect-social-acc pt-4">
                <section
                  v-if="
                    selectedAdAccount.facebook &&
                      selectedAdAccount.facebook.length > 0
                  "
                >
                  <div class="source-name-row">
                    <div class="wrapper">
                      <img
                        src="@/assets/logos/fb_logo_circle.png"
                        alt="facebook connect"
                        class="source-logo"
                      />
                      <span class="source-name">
                        FACEBOOK
                      </span>
                      <span class="source-count">
                        ({{ selectedAdAccount.facebook.length }})
                      </span>
                    </div>
                  </div>
                  <base-card class="table-wrapper">
                    <div class="ad-account-table table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="text-center" scope="col">
                              ชื่อบัญชีโฆษณา
                            </th>
                            <th class="text-center" scope="col">หมายเลข ID</th>
                            <th class="text-center" scope="col">สถานะ</th>
                            <th scope="col">เจ้าของบัญชีโฆษณา</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-if="
                              !selectedAdAccount.facebook ||
                                selectedAdAccount.facebook.length === 0
                            "
                          >
                            <td colspan="3" class="text-center">No data</td>
                          </tr>
                          <tr
                            v-for="acc in selectedAdAccount.facebook"
                            :key="acc.id"
                          >
                            <td>
                              {{ acc.name }}
                            </td>
                            <td class="text-center">{{ acc.id }}</td>
                            <td class="text-center">
                              <div class="badge badge-success">
                                ปกติ
                              </div>
                            </td>
                            <td>{{ acc.owner }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </base-card>
                </section>
              </div>
            </div>
            <div
              class="step-4 w-100 d-flex flex-column flex-center-center"
              v-if="stepIndex === 4"
            >
              <img
                class="success-logo"
                src="@/assets/process-success.png"
                alt="success"
              />
              <div class="title">ดำเนินการสำเร็จ</div>
              <div class="subtitle">
                คุณสามารถเริ่มต้นใช้งาน 1palette ได้ทันทีี
              </div>
              <button
                class="btn btn-primary"
                @click="$router.push({ name: 'Home' })"
              >
                ไปยังแบรนด์ของคุณ
              </button>
            </div>
          </base-card>
          <div class="bottom-row" v-if="stepIndex < 4">
            <div class="btn btn-light" @click="previousStep()">
              <span v-if="stepIndex === 0"> ยกเลิก</span>
              <span v-else>ย้อนกลับ</span>
            </div>
            <div
              class="btn btn-primary"
              v-if="stepIndex === 3 && !hasAccountResult"
              @click="nextStep()"
            >
              ไว้คราวหน้า
            </div>
            <button
              class="btn btn-primary"
              :disabled="isDisabledForward"
              @click="nextStep()"
              v-else
            >
              ดำเนินการต่อ
            </button>
          </div>
        </div>
      </div>
    </div>
    <compact-modal ref="successModal">
      <div class="success-modal">
        <img
          class="success-logo"
          src="@/assets/check-circle.png"
          alt="success"
        />
        <div class="title">เชื่อมต่อสำเร็จ</div>
        <div class="subtitle">1palette เชื่อมต่อกับบัญชีโซเชียลสำเร็จแล้ว</div>
        <button class="btn btn-primary" @click="onConnectSocialOK">ตกลง</button>
      </div>
    </compact-modal>
    <compact-modal ref="confirmDeleteModal" :has-close-button="true">
      <div class="confirm-delete-modal">
        <div class="title">ลบบัญชีโซเชียล</div>
        <div class="subtitle">ยืนยันการลบบัญชีโซเชียลออกจากแบรนด์</div>
        <div class="text-right">
          <button class="btn btn-light" @click="onConfirmDeleteSocial(false)">
            ยกเลิก
          </button>
          <button class="btn btn-danger" @click="onConfirmDeleteSocial(true)">
            ลบ
          </button>
        </div>
      </div>
    </compact-modal>
    <compact-modal ref="adAccountModal" :has-close-button="true">
      <div class="ad-account-modal">
        <div class="title">เลือกบัญชีโฆษณา</div>
        <div class="subtitle">
          หากไม่พบบัญชีที่ต้องการ กรุณากดปุ่ม "เพิ่มบัญชีโฆษณา"
        </div>
        <div class="row search-row">
          <div class="col left-label">
            รายการบัญชีโฆษณาทั้งหมด
          </div>
          <div class="col right-control">
            <input type="text" class="form-control" placeholder="Search" />
            <button class="btn btn-info">+ เพิ่มบัญชีโฆษณา</button>
          </div>
        </div>
        <div class="ad-account-table table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="text-center" scope="col">ชื่อบัญชีโฆษณา</th>
                <th class="text-center" scope="col">หมายเลข ID</th>
                <th scope="col">เจ้าของบัญชีโฆษณา</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="!connectingAdAccount || connectingAdAccount.length === 0"
              >
                <td colspan="3" class="text-center">No data</td>
              </tr>
              <tr v-for="acc in connectingAdAccount" :key="acc.id">
                <td class="d-flex align-items-center">
                  <input
                    type="checkbox"
                    class="mr-2"
                    v-model="selectedConnectingAdAccount[acc.id]"
                  />{{ acc.name }}
                </td>
                <td class="text-center">{{ acc.id }}</td>
                <td>{{ acc.owner }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bottom-row">
          <button class="btn btn-light" @click="onConfirmConnecting(false)">
            ยกเลิก
          </button>
          <button class="btn btn-primary" @click="onConfirmConnecting(true)">
            ยืนยัน
          </button>
        </div>
      </div>
    </compact-modal>
  </div>
</template>

<script>
import Vue from 'vue';

import api from '@/services/api';
import fb from '@/services/fb';

import Stepper from '@/components/Stepper.vue';
import BaseCard from '@/components/Base/BaseCard.vue';

import CompactModal from '@/components/Modal/CompactModal.vue';

const stepList = [
  { name: 'ข้อมูลแบรนด์' },
  { name: 'แพ็คเกจ' },
  { name: 'บัญชีโซเชียล' },
  { name: 'บัญชีโฆษณา' },
];

const packageList = [
  {
    id: 'starter',
    name: 'Starter',
    price: 490,
    adv: ['Basic Segmentation', 'Sales Funnel', 'Ad Targeting'],
  },
  {
    id: 'advanced',
    name: 'Advanced',
    price: 8490,
    adv: ['Advanced Segmentation', 'RFM', 'Ad Targeting'],
  },
  {
    id: 'enterprise',
    name: 'Enterprise',
    price: 22490,
    adv: [
      'Custom Segmentation',
      'Persona Clustering',
      'Personalization engine',
    ],
  },
];

export default {
  name: 'Subscribe',
  components: {
    Stepper,
    BaseCard,
    CompactModal,
  },
  data() {
    return {
      // Start from 0
      stepList,
      stepIndex: 3,
      // Package select
      packageList,
      selectedPackage: 'advanced',
      // Input brand
      brandName: '',
      brandCategory: '',
      // Social / page account
      hasConnectResult: true,
      showConnectResult: true,
      connectedSocialAccount: {
        facebook: [
          {
            name: 'Dummy Page',
            owner: 'John Lorem',
            createdAt: '7 Apr, 2021 at 5:50 PM',
          },
        ],
      },
      // Ad Account
      hasAccountResult: false,
      showAccountResult: false,
      // From connect source -> connecting ad acc -> selectConnecting -> selected
      connectingAdAccount: [
        {
          name: 'Advertisement',
          id: '11223344556677',
          owner: 'John Lorem',
        },
        {
          name: 'View only',
          id: '12233412123123',
          owner: 'John Lorem',
        },
      ],
      selectedConnectingAdAccount: {},
      selectedAdAccount: {},
      // Remove Social
      removingSocialAccType: null,
      removingSocialAccIndex: null,
    };
  },
  mounted() {
    // await fb.init(api.fbAppID);
    // window.FB.XFBML.parse();
    // this.$refs.adAccountModal.show();
  },
  methods: {
    previousStep() {
      if (this.stepIndex === 0) {
        // TODO cancel flow
        console.log(
          '🚀 ~ file: Subscribe.vue ~ line 87 ~ cancelFlow ~ cancelFlow'
        );
      }
      this.stepIndex -= 1;
    },
    nextStep() {
      // TODO check validity before continue
      if (this.stepIndex === 2 && !this.showConnectResult) {
        this.showConnectResult = true;
        return;
      }
      this.stepIndex += 1;
    },
    async connectSocialFB() {
      // TODO let's connect it
      await fb.logout().catch(() => {});
      const loginResult = await fb.login({ scope: api.fbScope });
      console.log(loginResult);
      // TODO remove this when had real new api
      // TODO get connected page
      this.connectedSocialAccount.facebook.push({ name: 'Connected Page 1' });
      this.$refs.successModal.show();
    },
    onConnectSocialOK() {
      this.$refs.successModal.hide();
      this.showConnectResult = true;
      // TODO show social account list
    },
    connectMoreSocial() {
      this.$refs.successModal.hide();
      this.showConnectResult = false;
    },
    onDeleteSocial(src, srcIndex) {
      this.removingSocialAccType = src;
      this.removingSocialAccIndex = srcIndex;
      this.$refs.confirmDeleteModal.show();
    },
    onConfirmDeleteSocial(state) {
      // TODO Add API
      if (state) {
        Vue.delete(
          this.connectedSocialAccount[this.removingSocialAccType],
          this.removingSocialAccIndex
        );
        this.removingSocialAccType = null;
        this.removingSocialAccIndex = null;
      }
      this.$refs.confirmDeleteModal.hide();
    },
    onConnectingAccount() {
      this.$refs.adAccountModal.show();
    },
    onConfirmConnecting(state) {
      if (state) {
        // TODO do real select / keep another source
        const newAcc = { facebook: [] };
        for (var i = 0; i < this.connectingAdAccount.length; i += 1) {
          const acc = this.connectingAdAccount[i];
          if (this.selectedConnectingAdAccount[acc.id]) {
            newAcc.facebook.push(acc);
          }
        }
        this.selectedAdAccount = newAcc;
        this.hasAccountResult = true;
        this.showAccountResult = true;
      }
      this.$refs.adAccountModal.hide();
    },
  },
  computed: {
    isDisabledForward() {
      if (this.stepIndex === 2) {
        return !this.hasConnectResult;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscribe {
  padding: 2rem;
  height: 100vh;
  background-color: #fcfcfc;
  .step-head {
    text-align: center;
    .title {
      font-size: 30px;
      font-weight: bold;
      color: #161616;
    }
  }
  .step-content {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .step-content-title {
      font-size: 24px;
      line-height: 36px;
      font-weight: bold;
      color: #161616;
      margin-bottom: 54px;
      text-align: center;
    }
    .step-content-subtitle {
      font-size: 16px;
      line-height: 24px;
      color: #4a4a4a;
      margin-bottom: 20px;
      text-align: center;
    }
    .step-content-expand {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .card-h100 {
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.05);
      height: 100%;
      min-height: 0;
      max-height: 100%;
    }
    .bottom-row {
      flex: 0;
      padding-bottom: 32px;
      @include flex-between-center;
    }
    .package-card {
      min-width: 200px;
      max-width: 200px;
      max-height: 184px;
      min-height: 184px;
      text-align: center;
      padding: 20px;
      position: relative;
      border: 1px solid transparent;
      .selected-circle {
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: #19a4b5;
        border-radius: 20px;
        width: 20px;
        height: 20px;
        .fa-check {
          color: #fff;
          font-size: 12px;
          line-height: 20px;
        }
      }
      &.selected {
        border: 1px solid #19a4b5;
        .selected-circle {
          display: block;
        }
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #161616;
        margin-bottom: 8px;
      }
      .price-row {
        margin-bottom: 8px;
      }
      .price {
        font-size: 24px;
        font-weight: bold;
        color: #1c3361;
        margin-right: 5px;
        line-height: 36px;
      }
      .unit {
        font-size: 10px;
        color: #979797;
      }
      .adv {
        font-size: 12px;
        line-height: 18px;
        color: #666;
        text-align: left;
        margin-bottom: 3px;
        .fa-check {
          color: #0fb053;
          margin-right: 5px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .source-card {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 24px 16px 10px 16px;
      text-align: center;
      min-width: 153px;
      margin: 10px;
      .source-logo {
        height: 40px;
        width: auto;
        margin-bottom: 8px;
      }
      .source-title {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px;
        font-weight: bold;
        color: #4a4a4a;
      }
      .connect-btn {
        background-color: #e8f6f8;
        border-color: #e8f6f8;
        color: #19a4b5;
      }
    }
    .connect-social-acc {
      .source-name-row {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        line-height: 18px;
        height: 0;
        margin-bottom: 2rem;
        border-bottom: 1px solid #ddd;
        .wrapper {
          background-color: #fff;
          display: flex;
          align-items: center;
          padding-right: 15px;
          .source-logo {
            height: 18px;
            width: 18px;
            margin-right: 10px;
          }
          .source-name {
            color: #1c3361;
            margin-right: 5px;
          }
          .source-count {
            color: #979797;
          }
        }
      }
      .acc-wrapper {
        .source-long-row {
          min-height: 90px;
          max-height: 120px;
          margin-right: 0;
          margin-bottom: 1rem;
          align-items: center;
          display: flex;
          .circle-img {
            border-radius: 50px;
            width: 50px;
            height: 50px;
            overflow: hidden;
            background-color: #c4c4c4;
          }
          .middle-content {
            flex: 1 1 auto;
            padding-left: 1rem;
            .title {
              color: #161616;
              font-size: 16px;
              font-weight: bold;
              line-height: 24px;
              margin-bottom: 3px;
            }
            .subtitle {
              color: #666;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
      .table-wrapper {
        padding: 0 1rem;
        margin: 0;
        min-height: 0;
        max-height: fit-content;
        .ad-account-table {
          max-height: 240px !important;
          overflow: auto;
          thead tr th {
            border-top: none;
          }
        }
      }
    }
    .step-4 {
      padding: 70px 0;
      .success-logo {
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        color: #161616;
        margin-bottom: 10px;
      }
      .subtitle {
        font-size: 14px;
        line-height: 22px;
        color: 4a4a4a;
        margin-bottom: 30px;
      }
    }
  }
  .success-modal,
  .confirm-delete-modal {
    width: 420px;
    max-width: 80vw;
    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 4px;
    }
    .subtitle {
      font-size: 14px;
      line-height: 22px;
      color: #979797;
      margin-bottom: 30px;
    }
    .btn {
      min-width: 125px;
      margin-left: 1rem;
    }
  }
  .success-modal {
    text-align: center;
    .success-logo {
      margin-top: 24px;
      height: 80px;
      width: 80px;
      margin-bottom: 20px;
    }
  }
  .confirm-delete-modal {
    .title {
      margin-top: 8px;
    }
    .subtitle {
      color: #4a4a4a;
      margin-top: 20px;
    }
  }
  .ad-account-modal {
    width: 862px;
    max-width: 80vw;
    .title {
      font-size: 24px;
      line-height: 36px;
      color: #161616;
      font-weight: bold;
      text-align: center;
      margin-bottom: 8px;
    }
    .subtitle {
      font-size: 16px;
      line-height: 24px;
      color: #4a4a4a;
      text-align: center;
    }
    .search-row {
      margin: 24px 0;
      padding: 0;
      align-items: center;
      .left-label {
        padding: 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
      }
      .right-control {
        padding: 0;
        display: flex;
        .btn {
          white-space: nowrap;
          margin-left: 0.5rem;
        }
      }
    }
    .ad-account-table {
      max-height: 60vh;
      overflow: auto;
      td {
        vertical-align: middle;
        font-size: 14px;
        line-height: 18px;
      }
      .table thead th {
        color: #1c3361;
      }
    }
    .bottom-row {
      text-align: right;
      margin-top: 24px;
      .btn {
        min-width: 125px;
        margin-left: 1rem;
      }
    }
  }
}
</style>
