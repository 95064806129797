/* eslint-disable no-unused-vars, max-len */

import axios from 'axios';
import Cookies from 'js-cookie';

const apiMainUrl = '/1palette-api';
const adsApiUrl = `${apiMainUrl}/1palette-ads`;
const crmApiUrl = `${apiMainUrl}/1palette-crm`;
const authenticationUrl = `${apiMainUrl}/authentication`;
const authV2Url = `${apiMainUrl}/authentication-revise`;

const fbAppID = '1044684192536766';
const fbScope =
  'email,pages_show_list,pages_messaging,pages_read_engagement,pages_read_user_content,pages_manage_ads,pages_manage_metadata,public_profile,read_insights,email,ads_read,ads_management';
const loginOnlyScope = 'email,public_profile';

// Interceptors for API call
const apiInterceptor = response => {
  // Auto extract data from response
  if (response.data) {
    if (response.data.data) {
      return response.data.data;
    }
    return response.data;
  }
  return response;
};

// Setup Global intercepter
axios.interceptors.response.use(apiInterceptor, error => Promise.reject(error));

// Api Instance + intercepter
const apiInstance = axios.create({
  timeout: 600000,
});
apiInstance.interceptors.response.use(apiInterceptor, error =>
  Promise.reject(error)
);

function apiRequest(url, method, path, data, params) {
  return apiInstance
    .request({
      method,
      url: `${url}/${path}`,
      data,
      params,
    })
    .catch(e => {
      if (e.response && e.response.status === 401) {
        // Prevent redirect login -> session timeout
        console.warn('REMOVE ALLOW 401 SKIP LOGIC');
        throw e;
        // if (window.location.pathname !== '/login') {
        //   const hostString = window.location.host;
        //   if (hostString.includes('1palette.com')) {
        //     Cookies.remove('auth', { path: '/', domain: '.1palette.com' });
        //   } else {
        //     Cookies.remove('auth');
        //   }
        //   if (window.localStorage) {
        //     window.localStorage.clear();
        //   }
        //   window.location = '/session-timeout';
        // }
      } else {
        // TODO handle more error here if possible
        throw e;
      }
    });
}

function getReq(apiUrl, path, params) {
  return apiRequest(apiUrl, 'get', path, undefined, params);
}

function delReq(apiUrl, path, params) {
  return apiRequest(apiUrl, 'delete', path, undefined, params);
}

function postReq(apiUrl, path, data, params) {
  return apiRequest(apiUrl, 'post', path, data, params);
}

function putReq(apiUrl, path, data, params) {
  return apiRequest(apiUrl, 'put', path, data, params);
}

function setApiAuthHeader(token) {
  apiInstance.defaults.headers.common.Authorization = token;
}

/* deprecated */
function authenticate(code, from, isExtend) {
  const o = window.location.origin;
  const rUrl = `${o}/cb`;
  const formData = new FormData();
  formData.set('client_id', 'XBQv3ce5K4uMZKpNFBkNVwbH');
  formData.set('grant_type', 'facebook_authorization_code');
  formData.set('redirect_uri', rUrl);
  formData.set('code', code);
  formData.set('login_from', from);
  if (isExtend) {
    formData.set('extend_inactive', true);
  }
  return axios.request({
    method: 'post',
    url: `${authenticationUrl}/oauth/token`,
    headers: {
      authorization:
        'Basic WEJRdjNjZTVLNHVNWktwTkZCa05Wd2JIOkRrRVI4QWg5S3VSWUJmbjZxQTI5QnpGOA==',
      contentType: 'application/x-www-form-urlencoded',
    },
    data: formData,
  });
}

/* deprecated */
function getLoginFBUrl() {
  const url = 'https://www.facebook.com/v7.0/dialog/oauth';
  const clientID = `client_id=${fbAppID}`;
  const respType = 'response_type=code%20token';
  const scope = `scope=${fbScope}`;
  const currentUrl = window.location.origin;
  const redir = `redirect_uri=${currentUrl}/cb`;
  return `${url}?${clientID}&${respType}&${scope}&${redir}`;
}

function loginWithFacebook(accessToken, from = 'facebook') {
  const formData = new FormData();
  formData.set('client_id', 'XBQv3ce5K4uMZKpNFBkNVwbH');
  formData.set('grant_type', 'social');
  formData.set('access_token', accessToken);
  formData.set('login_from', from);
  return axios.request({
    method: 'post',
    url: `${authV2Url}/oauth/token`,
    headers: {
      authorization:
        'Basic WEJRdjNjZTVLNHVNWktwTkZCa05Wd2JIOkRrRVI4QWg5S3VSWUJmbjZxQTI5QnpGOA==',
      contentType: 'application/x-www-form-urlencoded',
    },
    data: formData,
  });
}

function loginWithEmail(email, password) {
  const formData = new FormData();
  formData.set('grant_type', 'password');
  formData.set('username', email);
  formData.set('password', password);
  return axios.request({
    method: 'post',
    url: `${authV2Url}/oauth/token`,
    headers: {
      authorization: 'Basic Y2xpZW50X2lkOnNlY3JldA==',
      contentType: 'application/x-www-form-urlencoded',
    },
    data: formData,
  });
}

// prettier-ignore
export default {
  // FB App,
  fbAppID,
  fbScope,
  loginOnlyScope,
  getLoginFBUrl,
  // Authen
  authenticate,
  setApiAuthHeader,
  // Authen revise
  loginWithFacebook,
  loginWithEmail,
  registerUsingEmail: (formData) => postReq(authV2Url, 'user/register/1palette', formData),
  registerUsingFacebook: (formData) => postReq(authV2Url, 'user/register/facebook', formData),
  checkAccountFacebook: (accessToken) => getReq(authV2Url, 'user/register/facebook/check', { access_token: accessToken}),
  getTokenInfo: () => getReq(authV2Url, 'principal/me'),
  // API
  getOwnPageList: (internalId, activeOnly, forceUpdate) => getReq(adsApiUrl, `user/${internalId}/facebook/page`, { 'active-only': activeOnly, 'force-update': forceUpdate }),
  getPageSegmentByUser: userId => getReq(crmApiUrl, `page/user/${userId}/segment`),
  getOwnAdAccountList: (internalId, activeOnly, businessOnly, forceUpdate) => getReq(adsApiUrl, `user/${internalId}/facebook/ad-account`, { 'active-only': activeOnly, 'business-only': businessOnly, 'force-update': forceUpdate }),
};
