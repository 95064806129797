<template>
  <section class="signup-form">
    <md-field>
      <md-input type="text" placeholder="Full Name" v-model="fullName" />
    </md-field>
    <md-field>
      <md-input type="text" placeholder="Email" v-model="email" />
    </md-field>
    <md-field>
      <md-input type="text" placeholder="Phone Number" v-model="phone" />
    </md-field>
    <md-field v-if="step === 'signup'">
      <md-input type="password" placeholder="Password" v-model="pass" />
    </md-field>
    <div class="text-left py-3">
      <input type="checkbox" v-model="acceptTerm" />
      <span class="px-1">ฉันได้อ่านและยอมรับ</span>
      <span class="link-info" @click="showTermModal">เงื่อนไขการบริการ</span>
      <span class="px-1">และ</span>
      <span class="link-info" @click="showPrivacyModal"
        >นโยบายความเป็นส่วนตัว</span
      >
    </div>
    <div>
      <button class="btn btn-info btn-block" @click="registerAccount">
        ดำเนินการต่อ
      </button>
    </div>
    <div class="pt-4">
      <span class="pr-1">มีบัญชีแล้วใช่ใหม?</span>
      <span
        class="link-info font-weight-bold"
        @click="$emit('changeStep', 'login')"
      >
        เข้าสู่ระบบ
      </span>
    </div>
    <term-modal ref="termModal" />
    <privacy-modal ref="privacyModal" />
  </section>
</template>

<script>
import api from '@/services/api';

import TermModal from './TermModal.vue';
import PrivacyModal from './PrivacyModal.vue';

export default {
  name: 'SignupForm',
  components: {
    TermModal,
    PrivacyModal,
  },
  props: {
    step: String,
    token: String,
    prefill: Object,
  },
  watch: {
    prefill() {
      if (this.prefill) {
        const { email, fullname, phone } = this.prefill;
        this.email = email;
        this.fullName = fullname;
        this.phone = phone;
      }
    },
  },
  data() {
    return {
      fullName: '',
      email: '',
      phone: '',
      pass: '',
      acceptTerm: false,
    };
  },
  methods: {
    async registerAccount() {
      // TODO better check accept TERM with good modal
      if (!this.acceptTerm) {
        alert('Please accept term first!');
        return;
      }
      // TODO validate all form field
      const formData = {
        fullName: this.fullName,
        email: this.email,
        tel: this.phone,
      };
      let result = null;
      if (this.step === 'signup') {
        // Do register
        formData.password = this.pass;
        result = await api.registerUsingEmail(formData).catch(e => {
          console.log('register failed: email', e);
        });
        // Result example
        // createdAt: "2021-04-01T07:44:15.936+0000"
        // email: "test@test.com"
        // fbAsid: null
        // fullName: "test"
        // id: "606579cfadf6496405bcd86d"
        // loginType: "ONE_PALETTE"
        // password: "[PROTECTED]"
        // role: "user"
        // status: "ERROR"
        // tel: "01010101"
        // updatedAt: "2021-04-01T07:44:15.936+0000"
      } else if (this.step === 'fb-new') {
        formData.accessToken = this.token;
        result = await api.registerUsingFacebook(formData).catch(e => {
          console.log('register failed: fb', e);
        });
      }
      console.log('register result', result);
      if (result && result.id) {
        console.log('Regis success?', result.id);
        // TODO show some success badge or modal
        this.$emit('changeStep', 'login');
      }
    },
    showTermModal() {
      // TODO WTF is this LOLLL!!
      this.$refs.termModal.$refs.innerTermModal.show();
    },
    showPrivacyModal() {
      this.$refs.privacyModal.$refs.innerPrivacyModal.show();
    },
  },
};
</script>

<style></style>
