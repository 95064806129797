<template>
  <div class="stepper">
    <div class="container py-4">
      <div class="stepper-box position-relative d-flex">
        <template v-for="(step, index) in stepperState">
          <div
            class="stepper-wrap"
            :key="index"
            :class="{ inProgress: index < currentStepIndex }"
          >
            <div
              class="stepper-btn"
              @click="goToStep(step, index)"
              :class="{ undone: index > currentStepIndex }"
            >
              <span
                v-if="
                  index >= currentStepIndex && index !== stepperState.length
                "
              >
                {{ index + 1 }}
              </span>
              <i class="fa fa-check" v-else></i>
            </div>
            <div
              class="stepper-title"
              :class="{ undone: index > currentStepIndex }"
            >
              {{ step.name }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Stepper',
  props: {
    currentStepIndex: Number,
    navigationMode: {
      type: String,
      default: 'none',
    },
    stepperState: {
      type: Array,
      default: () => [
        { name: 'เลือก Channel' },
        { name: 'เลือกบัญชีโฆษณา' },
        { name: 'ตั้งค่า' },
        { name: 'เสร็จสิ้น' },
      ],
    },
  },
  methods: {
    goToStep(step, index) {
      if (this.navigationMode !== 'none') {
        if (index < this.currentStepIndex && step.route) {
          // Less mode
        }
      }
      this.$emit('stepToggle', { step, index });
    },
  },
};
</script>
<style lang="scss" scoped>
.stepper {
  min-height: 115px;
  .stepper-box {
    width: 100%;
    .stepper-wrap {
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: -1rem;
      min-width: 80px;
      position: relative;
      .stepper-btn {
        z-index: 10;
        width: 40px;
        height: 40px;
        background: #0fb053;
        border-radius: 50%;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        &.undone {
          background: #c4c4c4;
        }
      }
      .stepper-title {
        font-size: 16px;
        line-height: 1rem;
        position: absolute;
        bottom: -28px;
        color: #0fb053;
        &.undone {
          color: #aaa;
        }
      }
      &:not(:last-child):after {
        content: '';
        width: 100%;
        position: absolute;
        top: 18px;
        left: 50%;
        height: 3px;
        background: #c4c4c4;
      }
      &.inProgress:not(:last-child):after {
        background: #0fb053;
      }
    }
  }
}
</style>
