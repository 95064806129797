<template>
  <div class="login-fb-section">
    <div class="font bold size32 py-4 my-4">
      <span v-if="step === 'login'">
        เข้าสู่ระบบ 1palette
      </span>
      <span v-else>
        สร้างบัญชี 1palette
      </span>
    </div>
    <button
      v-if="step === 'signup'"
      class="btn btn-primary btn-block fb w-100"
      @click="$emit('click')"
    >
      <i class="fa fa-facebook"></i>
      <span>
        Continue with Facebook
      </span>
    </button>
    <button
      v-if="step === 'login'"
      class="btn btn-primary btn-block fb w-100"
      @click="$emit('click')"
    >
      <i class="fa fa-facebook"></i>
      <span>
        Login with Facebook
      </span>
    </button>
    <div class="mt-4 mb-3">
      หรือ
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: String,
  },
};
</script>

<style lang="scss" scoped>
.login-fb-section {
  .fb {
    background-color: #1877f2;
    border: none;
    padding: 8px 20px;
    @include flex-center-center;
    .fa-facebook {
      color: #1877f2;
      background-color: #fff;
      border-radius: 20px;
      padding: 2px 5px 0 5px;
      margin-right: 5px;
    }
  }
}
</style>
